<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<h3>Item Metadata/Change History</h3>

	<p>From an <span v-html="link('item_card', 'item’s card')"></span> or a <span v-html="link('document_card', 'document’s card')"></span>, click the <v-icon small>fas fa-code</v-icon> icon or the <nobr>“<v-icon small>fas fa-code</v-icon> ALL DOCUMENT METADATA”</nobr> button to see an expanded view of the item/document CASE metadata:</p>
	<p><img srcset="/docimages/more_info-1.png 2x" class="k-help-img"></p>
	<ul>
		<li>Each line shows a CASE metadata field (e.g. the “Identifier”, “Full Statement”, or “Last Changed Date”) on the left, and the field’s value on the right. Click the <v-icon small>fas fa-copy</v-icon> copy icon to copy the value to your clipboard.</li>
		<li>Click the SHOW JSON button to show the raw json value for the item or document.</li>
	</ul>
	<p>Click the SHOW CHANGE HISTORY button to reveal a detailed history of archived changes that have been made to the item or document:</p>
	<p><img srcset="/docimages/more_info-2.png 2x" class="k-help-img"></p>
	<ul>
		<li>At the bottom of the list of changes, under the <b class="orange darken-2 white--text px-1">orange header</b>, you’ll see the item’s white--text px-1 metadata when it was first created.</li>
		<li>Reading up from the bottom, each <b class="pink darken-2 white--text px-1">maroon header</b> shows a subsequent update white--text px-1 of some of the item’s metadata.</li>
		<li>The date in each header indicates when the change occurred; on the right side of the header, hover over the word ARCHIVE to see the description of the archive where this change was first recorded, along with the email of the editor who saved the archive.</li>
		<li>In each metadata field line in the change history, changes from the previous version of the item are highlighted with a <span class="k-diff-new">blue background</span>. For instance, in the screenshot above, we can see that the Full Statement for the item was amended to begin with the text “**Source Analysis:**”, and that this change was made on 7/19/2022 at 12:02.</li>
		<li>If you are currently editing the framework, you may see a <v-icon small>fas fa-clock-rotate-left</v-icon> icon next to some fields. Click this button to revert the field to the archived value (you will be asked to confirm this change in a dialog box before the change is actually made).</li>
	</ul>
</div></template>

<script>
	import HelpMixin from './HelpMixin'
	export default {
		mixins: [HelpMixin],
	}
</script>

<style lang="scss">
</style>
	